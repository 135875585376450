.navbar.transparent {
  background: none;
}

.navbar.transparent nav a {
  color: #000000bd;
  will-change: color;
  transition: all .2s ease-in-out;
  position: relative;
  transform: translateY(0);
}

.navbar.transparent nav a:after {
  content: "";
  height: 2px;
  background: #0076ab;
  transition: all .2s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  transform: translateY(-5px);
}

.navbar.transparent nav a:hover {
  color: #005378;
  transform: translateY(-5px);
}

.navbar.transparent nav a:hover:after {
  content: "";
  height: 2px;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(0);
}

.navbar-brand img {
  position: relative;
  top: 12px;
}

.nav-link {
  text-transform: uppercase;
}

@media (max-width: 992px) {
  .navbar.transparent .navbar-collapse {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 999999;
    background-color: #ffffffb3;
    border-radius: 14px;
    padding: 10px;
  }
}

/*# sourceMappingURL=navbar-site.css.map */
