@import "colors";
@import "variables";
.navbar.transparent {
  background: none;

  nav a {
    color: $main-text-color;
    position:relative;
    transform:translateY(0);
    transition:.2s all ease-in-out;
    will-change:color;
    &:after{
      position:absolute;
      content:'';
      bottom:0;
      height:2px;
      background:$main-bg-color;
      left:50%;
      right:50%;
      transition:.2s all ease-in-out;
      transform:translateY(-5px);
    }
    &:hover{
      color:darken($main-bg-color, 10%);
      transform:translateY(-5px);
      &:after{
        transform:translateY(0);
        content:'';
        bottom:0;
        height:2px;
        left:0;
        right:0;
      }
    }
  }
}

.navbar-brand {
  img {
    position: relative;
    top: 12px;
  }
}
.nav-link{
  text-transform: uppercase;
}

@media all and (max-width: 992px) {
  .navbar.transparent {
    .navbar-collapse {
      padding: 10px;
      border-radius: 14px;
      background-color: rgba(255,255,255, 0.7);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      z-index: 999999;
    }
  }
}